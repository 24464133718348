import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import FamilleControleCoherence from '../../enums/FamilleControleCoherence';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../interfaces/MontantControleCoherence';

class EquilibreSectionInvestissement extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.EQUILIBRE_BUDGETAIRE;
  }

  getExplicatif(): string {
    const depenseInvestissement = formatMontant(this.montants.depenseInvestissement, 2, true);
    const recetteInvestissement = formatMontant(this.montants.recetteInvestissement, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le total des prévisions inscrites en dépenses d'investissement (${depenseInvestissement}) est supérieur aux recettes d'investissement (${recetteInvestissement}).`;
      case StatutControleCoherence.OK:
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement est égal aux recettes d'investissement (${recetteInvestissement}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return "Déséquilibre de la section d'investissement";
      case StatutControleCoherence.OK:
        return "Équilibre de la section d'investissement";
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseInvestissement, recetteInvestissement } = this.montants;

    if (depenseInvestissement === recetteInvestissement) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreSectionInvestissement;
